.dropzone {
    width: 100%;
    cursor: pointer;
    border: 1px dashed #c4c4c4;
    text-align: center;
    transition: background-color 0.5s ease, border-color 0.3s ease;
}

    .dropzone:hover {
        background-color: #f5f5f5;
        border-color: transparent;
    }

    .dropzone.dragActive {
        background-color: #e3e3e3;
    }

    .dropzone p {
        width: 100%;
        padding: 36px 12px;
    }