.logo {
    max-height: 3rem !important;

    @media only screen and (min-width: 768px) {
        max-height: 4rem !important;
    }
}

.menu {
    @media only screen and (max-width: 768px) {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
}