.password {
    
    input {
        &::-ms-reveal,
        &::-ms-clear {
            display: none;
        }
    }

    span[class="input-group-text"] {
        
        &:hover {
            color: var(--bs-primary-text-emphasis);
            cursor: pointer;
        }

        &:active {
            color: black;
        }
    }

}