.label {
    font-size: .9375rem;
    font-weight: 500;
}

.editor {
    margin-top: 1rem;
    margin-bottom: 6rem;
}

.btn {
    border: none;
    color: #fff;
    border-radius: 20px;
    padding: 0.6rem 2rem;
    background-color: royalblue;
}